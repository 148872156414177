export const es = {
  theme: 'Tema',
  dark: 'Oscuro',
  light: 'Claro',
  spanish: 'Español',
  english: 'Inglés',
  close: 'Cerrar',
  sure: 'Seguro',
  cancel: 'Cancelar',
  yes: 'Si',
  no: 'No',
  emailSucces: 'El correo ha sido enviado satisfactoriamente',
  emailError: 'El correo no ha podido ser enviado',
  download: 'Descargar',
  menu: {
    aboutUs: 'Sobre Nosotros',
    team: 'Equipo',
    projects: 'Proyectos',
    sustainability: 'Sostenibilidad',
    contactUs: 'Contáctenos'
  },
  footer: {
    address:
      'D: 3rd piso de el Centro Corporativo Lindora, Santa Ana, Costa Rica.',
    employmentBoard: 'Bolsa de empleo:'
  },
  banner: {
    title: 'GARNIER',
    subTitle: 'Enhancing Land',
    paragraph: `
    Garnier & Garnier Desarrollos Inmobiliarios es un
    grupo costarricense con más de 25 años de experiencia en el desarrollo inmobiliario.`
  },
  team: {
    title: 'Equipo'
  },
  aboutUs: {
    title: 'Sobre Nosotros',
    paragraph: `Garnier & Garnier Desarrrollos Inmobiliarios es un 
    grupo costarricense con más de 25 años de experiencia 
    en el desarrollo inmobiliario. Se destaca en Costa Rica gracias a 
    los servicios integrales que ofrece a sus clientes y por la versatilidad 
    de sus emprendimientos según las necesidades del mercado.
    Garnier & Garnier se ha involucrado en el diseño, construcción, 
    venta y promoción de múltiples y exitosos proyectos inmobiliarios: 
    parques industriales y zonas francas, centros comerciales y resorts exclusivos 
    y condominios residenciales. La estructura empresarial de Garnier & Garnier 
    le permite concebir el proyecto de forma comprensiva e integral: 
    los clientes son asesorados y guiados durante todo el proceso. 
    Esto sin duda se convierte en su ventaja competitiva. 
    Desde su fundación, Garnier & Garnier asumió un estilo y visión corporativa 
    que le ha permitido crecer continuamente y posicionarse 
    como líder en el mercado costarricense. Los resultados de los desarrollos realizados 
    por el grupo son su mejor presentación, ratificando su experiencia y solidez.`,
    tabCorporateProfile: {
      title: 'Perfil corporativo',
      titleMobile: 'Perfil',
      firstParagraph: `Garnier & Garnier Desarrollos Inmobiliarios es un grupo costarricense con más
    de 25 años de experiencia en el desarrollo inmobiliario que se destaca en Costa
    Rica gracias a los servicios integrales que ofrece a sus clientes y por la  
    versatilidad de sus emprendimientos según las necesidades del mercado. Garnier & Garnier 
    ha participado en el diseño, construcción, venta y promoción de múltiples 
    proyectos inmobiliarios de éxito: parques industriales y zonas francas,
    centros comerciales y exclusivos resorts y condominios residenciales.`,
      secondParagraph: `La estructura empresarial de Garnier & Garnier le permite concebir el proyecto de forma integral y completa: los clientes son asesorados y guiados 
    durante todo el proceso. Esto se convierte, sin duda, en su ventaja competitiva. 
    Desde su fundación, Garnier & Garnier adoptó un estilo y una 
    visión corporativa que le ha permitido crecer continuamente y posicionarse 
    como líder en el mercado costarricense. Los resultados de los desarrollos 
    emprendidos por el grupo son su mejor carta de presentación, ratificando su experiencia y 
    solidez.`
    },
    tabValues: {
      title: 'Valores',
      responsibility: {
        title: 'Responsabilidad',
        paragraph: `Es el pilar fundamental que guía todas nuestras acciones. 
        Nos esforzamos incansablemente para alcanzar nuestros objetivos y 
        nos enorgullecemos de ser una empresa confiable y digna de confianza 
        para todos aquellos que se relacionan con nosotros. Nuestra preocupación 
        por el bienestar de nuestros clientes, el medio ambiente y las comunidades 
        en las que operamos es primordial, al igual que el cuidado y apoyo a nuestros 
        asociados y sus familias.`
      },
      integrity: {
        title: 'Integridad',
        paragraph: `Es la brújula que orienta cada paso que damos. 
        Realizamos nuestro trabajo con honestidad, transparencia y respeto, 
        siempre en sintonía con nuestros sólidos valores y principios. 
        Creemos firmemente en la importancia de actuar con coherencia y consistencia,
        manteniendo altos estándares éticos en cada situación que enfrentamos.`
      },
      commitment: {
        title: 'Compromiso',
        paragraph: `Es la base de nuestro orgullo de pertenecer a Garnier & Garnier. 
        Nos unimos en una sola visión, abrazando plenamente los objetivos y valores 
        de la empresa como propios. Trabajamos con determinación y pasión, superando 
        lo acordado o esperado, porque sabemos que es la dedicación y la entrega absoluta 
        las que nos impulsarán hacia el éxito y el progreso constante.`
      }
    },
    tabServices: {
      title: 'Servicios',
      firstParagraph: 'Garnier & Garnier es una empresa especializada en el sector inmobiliario que ofrece servicios integrales para el desarrollo de proyectos inmobiliarios. Su enfoque se basa en satisfacer las necesidades específicas de cada cliente, manteniendo canales de comunicación abiertos y eficaces Algunos de los aspectos fundamentales en los que la empresa destaca son:',
      secondParagraph:
        'Garnier & Garnier ofrecen servicios de:',
      thirdParagraph:
        'Su experiencia abarca desde la selección del terreno hasta la obtención de permisos, financiación y gestión de activos. Buscando establecer relaciones duraderas y de confianza con nuestros clientes.',
      fourthParagraph:
        'Asesoramiento técnico y profesional: Garnier & Garnier se destaca por contar con un equipo de profesionales especializados en el sector inmobiliario que pueden brindar asesoramiento experto en diferentes aspectos del desarrollo de proyectos.',
      fifthParagraph:
        'Experiencia en diferentes roles: La empresa ha trabajado como promotor inmobiliario, gestor de construcción y gestor de activos, lo que indica su versatilidad y capacidad para abordar distintas etapas del proceso inmobiliario.',
      SixthParagraph: 'Garnier & Garnier ofrecen servicios de:',
      listParagraph: {
        item1: 'Tiempo de respuesta: La prontitud en atender las solicitudes y consultas de los clientes es crucial para brindar un servicio eficiente y de calidad.​',
        item2: 'Seguimiento: Mantener un seguimiento cercano de los proyectos y las etapas de desarrollo es clave para garantizar que todo avance de acuerdo con los planes y las expectativas del cliente.​',
        item3: 'Asesoramiento técnico y profesional: Garnier & Garnier se destaca por contar con un equipo de profesionales especializados en el sector inmobiliario que pueden brindar asesoramiento experto en diferentes aspectos del desarrollo de proyectos.',
        item4: 'Experiencia en diferentes roles: La empresa ha trabajado como promotor inmobiliario, gestor de construcción y gestor de activos, lo que indica su versatilidad y capacidad para abordar distintas etapas del proceso inmobiliario.​'
      },
      list: {
        item1: 'Promotor inmobiliario',
        item2: 'Gestor de la construcción',
        item3: 'Gestor de activos'
      },
      SeventhParagraph: `Su experiencia abarca desde la selección del terreno hasta la obtención de permisos,
       financiación y gestión de activos. 
       Buscando establecer relaciones duraderas y de confianza con nuestros clientes.`
    },
    tabRelatedCompanies: {
      title: 'Empresas relacionadas',
      titleMobile: 'Empresas',
      mainText: 'Varias empresas complementan nuestros servicios:',
      cmpArchitecture: {
        type: 'Arquitectura',
        name: 'Garnier Arquitectos',
        webSite: 'www.garnierarquitectos.com'
      },
      cmpProperty: {
        type: 'Property Management',
        name: 'Property Pro',
        webSite: ''
      }
    }
  },
  contactUs: {
    title: 'Contáctenos',
    send: 'Enviar',
    name: 'Nombre',
    email: 'Correo',
    companyName: 'Compañía',
    message: 'Mensaje',
    needName: 'El nombre es requerido',
    needEmail: 'El correo es requerido',
    needCompanyName: 'La compañia es requerida',
    needMessage: 'El mensaje es requerido'
  },
  projects: {
    title: 'Proyectos Recientes',
    titleShort: 'Proyectos',
    swipeMsg:
      'Deslice para ver nuestros proyectos más recientes o pulse el botón para ver todos nuestros proyectos.',
    portfolio: 'Más Proyectos'
  },
  sustainability: {
    title: 'Sostenibilidad',
    imageTitle: 'Estrategia',
    firstParagraph:
      'Garnier estableció cinco pilares o bases corporativas para su operación y estrategia de sostenibilidad,  La empresa trabajó con expertos en diferentes campos para idear una MATRIZ de estándares o requisitos por cimentación, lo que inscentiva el análisis desde la etapa de planificación de cada desarrollo inmobiliario,  La matriz se convierte en una guía para establecer formal y pragmaticamente la visión de sostenibilidad en la empresa.',
    secondParagraph:
      'Cada una de las bases se desglosa en una serie de indicadores de desempeño y se debe cumplir con un total minimo para llevar a cabo el proyecto.  El comité de sostenibilidad monitorea cada proyecto para verificar la forma en que se implementa la matriz, utilizando un sistema de puntuación.',
    thirdParagraph:
      'El grado de implicación del equipo en la estrategia es la base para construir y hacer realidadd una visión integral de la sostenibilidad, por ello, esta estrategia va acompañada de un plan de cultura para que en cada decisión del proceso de desarrollo, todos en la empresa tengan la conciencia y los conocimientos básicos para priorizar la sostenibilidad integral.'
  },
  reports: {
    imageTitle: 'Reportes',
    download: 'Descargar Reportes',
    firstParagraph:
      'El contenido del informe anual de sostenibilidad de G&G es un medio para rendir cuentas a las partes interesadas y al público en general, respetando los principios de transparencia, claridad y confianza que merecen. Queremos compartir nuestras prácticas y aprendizajes con tantos actores de la industria como sea posible, con la convicción de que juntos podemos tener un impacto como industria en el país que queremos construir.'
  },
  portfolio: {
    title: 'Proyectos'
  },
  project: {
    header: {
      website: 'Sitio web'
    },
    type: {
      residential: 'Residencial',
      commercial: 'Comercial',
      free_zone_industrial: 'Zona Franca e Industrial',
      hotel_resort: 'Hotel & Resort',
      hospitalary: 'Hospitalario',
      empty: ''
    },
    detail: {
      area: 'Área',
      opening: 'Apertura',
      location: 'Ubicación'
    }
  }
};
